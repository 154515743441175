// React Required
import React, {Component, lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";
/*

import HomePa2rticles from './home/HomeParticles';
// Dark Home Layout
import Service from "./elements/Service";
import EquipmentDevices from "./elements/OurProducts/EquipmentDevices";
import LabSupplies from "./elements/OurProducts/LabSupplies";
import AntibodieImmunology from "./elements/OurProducts/AntibodieImmunology";
import MolecularBiologyReagents from "./elements/OurProducts/MolecularBiologyReagents";
import GeneticsPCRRagents from "./elements/OurProducts/GeneticsPCRRagents";
import ClinicalDiagnostics from "./elements/OurProducts/ClinicalDiagnostics";

import About from "./elements/About";
import Partners from "./elements/Partners";
import Contact from "./elements/Contact";
import Suppliers from "./elements/Suppliers";*/
// import error404 from "./elements/error404";


import {BrowserRouter, Switch, Route} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

const HomeParticles = lazy(() => import('./home/HomeParticles'));
const Service = lazy(() => import('./elements/Service'));
const EquipmentDevices = lazy(() => import('./elements/OurProducts/EquipmentDevices'));
const LabSupplies = lazy(() => import('./elements/OurProducts/LabSupplies'));
const AntibodieImmunology = lazy(() => import('./elements/OurProducts/AntibodieImmunology'));
const MolecularBiologyReagents = lazy(() => import('./elements/OurProducts/MolecularBiologyReagents'));
const GeneticsPCRRagents = lazy(() => import('./elements/OurProducts/GeneticsPCRRagents'));
const ClinicalDiagnostics = lazy(() => import('./elements/OurProducts/ClinicalDiagnostics'));
const About = lazy(() => import('./elements/About'));
const Partners = lazy(() => import('./elements/Partners'));
const Contact = lazy(() => import('./elements/Contact'));
const Suppliers = lazy(() => import('./elements/Suppliers'));
const error404 = lazy(() => import('./elements/error404'));

class Root extends Component {
    render() {
        return (
            <>
                <Suspense fallback={<></>}>
                    <BrowserRouter basename={'/'}>
                        <Switch>
                            <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeParticles}/>

                            <Route exact path={`${process.env.PUBLIC_URL}/equipment_devices`}
                                   component={EquipmentDevices}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/lab_supplies`} component={LabSupplies}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/antibodies_immunology`}
                                   component={AntibodieImmunology}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/molecular_biology_reagents`}
                                   component={MolecularBiologyReagents}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/genetics_PCR_reagents`}
                                   component={GeneticsPCRRagents}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/clinical_diagnostics`}
                                   component={ClinicalDiagnostics}/>

                            <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/suppliers`} component={Suppliers}/>

                            <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/about-us`} component={About}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/partners`} component={Partners}/>


                            <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                            <Route component={error404}/>

                        </Switch>
                    </BrowserRouter>
                </Suspense>
            </>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();
